import React, { useState, useEffect } from "react";
import locations from "../../data/locations.json"; // Json File with provincias, cantones and distritos

const DeliveryDetails = ({ deliveryOption, setDeliveryOption, metadata, handleInputChange }) => {
    const [cantones, setCantones] = useState([]);
    const [distritos, setDistritos] = useState([]);

    // Update cantones when the province changes
    const handleProvinceChange = (e) => {
        const selectedProvince = e.target.value;
        handleInputChange(e);
        setCantones(Object.keys(locations[selectedProvince]?.cantones || {})); // Convert object keys to array
        setDistritos([]); // Reset districts
    };
    
    // Update distritos when canton changes
    const handleCantonChange = (e) => {
        const selectedCanton = e.target.value;
        handleInputChange(e);
    
        // Get the selected province directly from the form
        const selectedProvince = document.getElementById("province").value;
    
        // Ensure we have a valid province before accessing its cantones
        if (!selectedProvince) {
            console.error("No se ha seleccionado una provincia");
            return;
        }
    
        const newDistritos = locations[selectedProvince]?.cantones[selectedCanton]?.distritos || [];
    
        setDistritos(newDistritos);
    };
    
    
    
    
    return (
        <>
            <h3 className="font-medium text-xl">Método de entrega</h3>

            {/* Delivery Options*/}
            <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 mb-6">
                <ul className="flex flex-row md:flex-wrap -mb-px">
                    <li className="me-2">
                        <button type="button" onClick={() => setDeliveryOption("store")}
                            className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors duration-300 
                                ${deliveryOption === "store" ? "text-blue-600 border-blue-600" : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300"}`}>
                            Recoger en tienda
                        </button>
                    </li>
                    <li className="me-2">
                        <button type="button" onClick={() => setDeliveryOption("home")}
                            className={`inline-block p-4 border-b-2 rounded-t-lg transition-colors duration-300 
                                ${deliveryOption === "home" ? "text-blue-600 border-blue-600" : "text-gray-500 border-transparent hover:text-gray-600 hover:border-gray-300"}`}>
                            Envío a domicilio
                        </button>
                    </li>
                </ul>
            </div>

            {/* Direction Form for "Envío a domicilio" */}
            {deliveryOption === "home" && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
                    {/* Provincia */}
                    <div className="mb-2">
                        <label htmlFor="province" className="text-[#373f50] text-sm">Provincia </label>
                        <select id="province" name="province" className="w-full p-2 border rounded" value={metadata.province} onChange={handleProvinceChange} required>
                            <option value="">Selecciona una provincia</option>
                            {Object.keys(locations).map((provincia) => (
                                <option key={provincia} value={provincia}>{provincia}</option>
                            ))}
                        </select>
                    </div>

                    {/* Cantón */}
                    <div className="mb-2">
                        <label htmlFor="canton" className="text-[#373f50] text-sm">Cantón </label>
                        <select id="canton" name="canton" className="w-full p-2 border rounded" value={metadata.canton} onChange={handleCantonChange} required disabled={!cantones.length}>
                            <option value="">Selecciona un cantón</option>
                            {cantones.map((canton) => (
                                <option key={canton} value={canton}>{canton}</option>
                            ))}
                        </select>
                    </div>

                    {/* Distrito */}
                    <div className="mb-2">
                        <label htmlFor="district" className="text-[#373f50] text-sm">Distrito </label>
                        <select id="district" name="district" className="w-full p-2 border rounded" value={metadata.district} onChange={handleInputChange} required disabled={!distritos.length}>
                            <option value="">Selecciona un distrito</option>
                            {distritos.map((distrito) => (
                                <option key={distrito} value={distrito}>{distrito}</option>
                            ))}
                        </select>
                    </div>

                    {/* Dirección exacta */}
                    <div className="mb-2">
                        <label htmlFor="address" className="text-[#373f50] text-sm">Dirección exacta </label>
                        <textarea id="address" name="address" placeholder="Dirección completa" className="w-full h-full p-2 border rounded" rows="4"
                            value={metadata.address}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                </div>
            )}

            {/* Show Map if "Recoger en tienda" is selected*/}
            {deliveryOption === "store" && (
                <div className="my-6">
                    <h4 className="font-medium text-lg">Ubicación de Ready Games</h4>
                    <p className="text-sm text-gray-600">Puedes recoger tu pedido en nuestra tienda.</p>
                    <p className="text-sm text-gray-600">Alajuela centro, 50m norte hacia la corte, a un costado del museo Juan.</p>
                    
                    <div className="mt-2 rounded-lg overflow-hidden shadow-md">
                        <iframe
                        title="Ready Games Location"
                        width="100%"
                        height="300"
                        style={{ border: 0 }}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.9930057059696!2d-84.21721402416688!3d10.017435172732807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8fa0f9734f3f260d%3A0x52bceac3af5e31fe!2sReady%20Games!5e0!3m2!1ses-419!2scr!4v1739335876190!5m2!1ses-419!2scr"
                        />
                    </div>
                </div>
            )}
        </>
    );
};

export default DeliveryDetails;
import React from 'react';
import {ConsoleLogo} from "../ConsoleLogo/ConsoleLogo";

const ProductCard = ({product}) => {

    return (
            <div key={"product-"+product.id} onClick={
                () => document.location.href = "/product/"+product.id
            } className="pb-12 flex flex-col h-full md:h-96 items-center justify-center border-2 border-zinc-200 rounded-lg bg-white p-4 hover:cursor-pointer">
                <img src={product.image} className="grow object-contain w-full md:h-4" alt={product.alt}  title={product.title}/>
                <h2 className="text-lg font-bold mb-2">{product.name}</h2>
                <ConsoleLogo console={product.console.console} consoleCode={product.console["console-code"]}></ConsoleLogo>
                <p>₡{product.payment.sale_price.toLocaleString()}</p>
                <div className="flex flex-row mt-4">
                    <div className={"m-2 p-1 rounded text-white " + (product.used ? "bg-amber-400": "bg-blue-800") }>{product.used ? "usado": "nuevo"}</div>
                    {product.tags.slice(0, 2).map(
                        (tag) => !tag.internal && <div key={"tag-" + tag.id} style={{backgroundColor: tag.color}} className="m-2 p-1 rounded text-white">{tag.name.toLowerCase()}</div>
                    )}
                </div>
            </div>
    );
};
export default ProductCard;
import React, { useState } from 'react';
import { FaChevronDown } from "react-icons/fa";

const CouponForm = ({ applyCouponCallback }) => {
    const [coupon, setCoupon] = useState("");
    const [message, setMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);

    const handleApply = () => {
        /*if (coupon.trim() === "DISCOUNT10") {
            setMessage("Cupon aplicado con exito! 🎉");
            applyCouponCallback();
        } else {*/
            setMessage("Codigo de cupon invalido. ❌");
        //}
    };

    return (
        <div className="p-4 bg-white rounded-lg border">
            <button 
                onClick={() => setIsOpen(!isOpen)} 
                className="w-full rounded flex justify-between items-center"
            >
                Aplicar Cupon
                <FaChevronDown className={`transition-transform ${isOpen ? "rotate-180" : "rotate-0"}`} />
            </button>
            {isOpen && (
                <div className="mt-2 pb-2">
                    <input 
                        type="text" 
                        placeholder="Ingrese el cupon" 
                        value={coupon} 
                        onChange={(e) => setCoupon(e.target.value)} 
                        className="p-2 border rounded w-full"
                    />
                    
                    {message && <p className="my-4 text-sm text-gray-600">{message}</p>}

                    <button onClick={handleApply} className="w-full p-2 border border-green-500 text-green-500 rounded mt-2">Aplicar Cupon</button>
                </div>
            )}
        </div>
    );
};

export default CouponForm;



import axios from "axios";

export class OnvopayAPI {
    constructor() {
        this.base_url = "https://readygamescr.com/api/administration";
    }

    /**
     * Creates a payment method using a POST request.
     * @param {Object} methodData - The card details for creating a payment method which includes card number, expiration month, expiration year, CVV, and holder's name.
     * @returns {Promise<Object>} A promise that resolves with the newly created payment method's data.
     */
    createPaymentMethod(methodData) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.base_url}/create-payment-method`, methodData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response || error.message);
            });
        });
    }

    /**
     * Creates a payment intent using a POST request.
     * @param {Object} paymentData - The data for creating a payment intent which includes amount, currency, userId, phoneNumber, userName, userEmail and description.
     * @returns {Promise<Object>} A promise that resolves with the payment intent data.
     */
    createPaymentIntent(paymentData) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.base_url}/create-payment-intent`, paymentData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response || error.message);
            });
        });
    }

    /**
     * Confirms a payment intent with a payment method.
     * @param {Object} confirmData - Data needed to confirm the payment intent which includes paymentIntentId and paymentMethodId.
     * @returns {Promise<Object>} A promise that resolves with the confirmation result.
     */
    confirmPaymentIntent(confirmData) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.base_url}/confirm-payment-intent`, confirmData)
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                reject(error.response || error.message);
            });
        });
    }

    /**
     * Creates a new customer using a POST request to the OnvoPay API.
     * @param {Object} customerData - The data to create a new customer.
     * @returns {Promise<Object>} A promise that resolves with the newly created customer's data.
     */
    createCustomer(customerData) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.base_url}/create-customer`, customerData)
            .then(response => {
                resolve(response.data); // Resolves the promise with the response data.
            })
            .catch(error => {
                reject(error.response || error.message); // Rejects the promise with the error.
            });
        });
    }

}
export const ErrorOnPay = (props) => {
    const stopPropagation = (e) => e.stopPropagation();

    return (
        <div onClick={() => { props.setShowErrorOnPay(false); }} className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex">
            <div onClick={stopPropagation} className="relative bg-white m-auto flex-col flex rounded-lg text-center px-4 py-8 overflow-hidden w-[450px]">
                <div className="flex flex-col flex items-center justify-center">
                    <img src="/images/x.png" alt="green-check" className="w-[10rem]" />
                    <p className="font-bold text-[2rem] mt-2">¡Error!</p>
                    <p>{props.error}</p>
                    <button className="w-[10rem] mt-2 p-2 bg-blue-500 text-white rounded hover:bg-blue-600" onClick={() => props.setShowErrorOnPay(false)}>Cerrar</button>
                </div>
            </div>
        </div>
    )
}
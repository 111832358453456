import { useEffect, useState, useRef } from "react";
import { OnvopayAPI } from "../../api/onvopay";
import { SuccessfullPay } from "./SuccessfullPay";
import { ErrorOnPay } from "./ErrorOnPay";
import { ProductsAPI } from "../../api/products";
import DeliveryDetails from "./DeliveryForm";
import { PaymentModal } from "../Modals/Payments/PaymentModal";
import ReCAPTCHA from "react-google-recaptcha";
import { getShipping } from "../../utils";


export const PaymentFlow = (props) => {
    const [recaptchaToken, setRecaptchaToken] = useState(null);

    const [showErrorOnPay, setShowErrorOnPay] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const apiOnvo = new OnvopayAPI();
    const productsApi = new ProductsAPI()
    const products = props.products || [].reduce;

    const [shipping, setShipping] = useState(0)
    const [taxesPlusService, setTaxesPlusSerive] = useState(0)
    const saleId = useRef(undefined);

    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [error, setError] = useState()
    const [loading, setLoading] = useState(false)

    // State for radio option
    const [deliveryOption, setDeliveryOption] = useState('store'); // default is "recoger en tienda"

    // State for shipping details (for "entrega a domicilio")
    const [shippingData, setShippingData] = useState({
        idType: 'fisica',
        id: '',
        name: '',
        phone: '',
        province: '',
        canton: '',
        district: '',
        address: '',
    });

    const [metadata, setMetadata] = useState({
        email: ""
    });

    const [cardData, setCardData] = useState({
        cardNumber: "",
        monthYear: "",
        CVV: "",
        holderName: ""
    });

    const [paymentData, setPaymentData] = useState({
        amount: 0,
        currency: "CRC",
        description: ""
    });

    const [minMonthYear, setMinMonthYear] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // calculate shipping
        if (deliveryOption === "store") {
            setShipping(0);
            return;
        }
        setShipping(getShipping(products))
    }, [products, deliveryOption])

    useEffect(() => {

        if (Array.isArray(products) && products.length > 0) {
            setTaxesPlusSerive(products.reduce(
                    (sum, prod) => sum + prod.payment.card - prod.payment.sale_price,
                    0
                )
            )
            const totalAmount = products.reduce(
                (sum, product) => sum + product.payment.card * 100, // Convert to minor unit (céntimos)
                0
            );

            const productDescriptions = products
                .map((product) => product.name)
                .join(", ");

            setPaymentData({
                amount: totalAmount + shipping * 100,
                currency: "CRC",
                description: `Purchase by ${
                    metadata.email || "unknown"
                }: ${productDescriptions}`,
            });
        }
    }, [products, metadata.email, shipping]);

    useEffect(() => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        setMinMonthYear(`${year}-${month}`);
    }, []);

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        
        return regex.test(email);
    };

    const validateCVV = (cvv) => {
        const regex = /^\d{3,4}$/;
        return regex.test(cvv);
    };

    const validateCardNumber = (number) => {
        const regex = /^\d{13,19}$/;
        return regex.test(number);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!validateEmail(metadata.email)) {
            newErrors.userEmail = 'Formato de correo invalido.';
        }
        if (!validateCVV(cardData.CVV)) {
            newErrors.CVV = 'CVV entre 3 y 4 digitos.';
        }
        if (!validateCardNumber(cardData.cardNumber)) {
            newErrors.cardNumber = 'Numero de tarjeta invalida.';
        }
        if (!recaptchaToken) {
            newErrors.recaptchaToken = 'Se ocupa el recaptcha.';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        

        setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));

        if (name === "CVV") {
            const sanitizedValue = value.replace(/\D/g, '').slice(0, 3);
            setCardData(prev => ({ ...prev, [name]: sanitizedValue }));
        } else if (Object.keys(metadata).includes(name)) {
            setMetadata(prev => ({ ...prev, [name]: value }));
        } else if (Object.keys(cardData).includes(name)) {
            setCardData(prev => ({ ...prev, [name]: value }));
        } else if (Object.keys(shippingData).includes(name)) {
            setShippingData(prev => ({ ...prev, [name]: value }));
        }
    };

    const formatCardDataForAPI = () => {
        const [expYear, expMonth] = cardData.monthYear.split("-");
        return {
            number: cardData.cardNumber,
            expMonth: parseInt(expMonth),
            expYear: parseInt(expYear),
            cvv: cardData.CVV,
            holderName: cardData.holderName
        };
    };

    const handleRecaptcha = (token) => {
        setRecaptchaToken(token);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;
        if (validateForm()) {
            setLoading(true);
            const formattedCardData = formatCardDataForAPI();
            try {
                const paymentMethod = await apiOnvo.createPaymentMethod(formattedCardData);
                const paymentIntent = await apiOnvo.createPaymentIntent({ ...paymentData, ...metadata, ...shippingData });
                const paymentConfirm = await apiOnvo.confirmPaymentIntent({ paymentIntentId: paymentIntent.paymentIntent.id, paymentMethodId: paymentMethod.paymentMethod.id });
                if (paymentConfirm?.charges.length > 0 && paymentConfirm.charges[0]?.failureCode === "declined") {
                    setShowPaymentModal(true);
                    return;
                }
                const total = products.map(prod => prod.payment.card).reduce((accumulator, 
                            currentValue) => accumulator+ currentValue, 0)
                const paymentDetails = deliveryOption === "home" ? `\n

                            tipo de ID: ${shippingData.idType}\n
                            ID: ${shippingData.id}\n
                            Nombre: ${shippingData.name}\n
                            Provincia: ${shippingData.province}\n
                            Canton: ${shippingData.canton}\n
                            Distrito: ${shippingData.district}\n
                            Direccion exacta: ${shippingData.address}\n
                            `: "Recoge en tienda";
                        
                const sale = await productsApi.createSale(
                    {
                        "store_name": "Ready Games",
                        "store_address": "50m norte del parque central de Alajuela. Costado del museo Juan Santamaria.",
                        "store_contact": "7237 5342",
                        "store_mail": "readygamescr@gmail.com",
                        "purchase_date": "10/02/2025",
                        "customer_name": shippingData.name,
                        "customer_mail": metadata.email,
                        "customer_phone": shippingData.phone,
                        "payment_method": "Credit Card/Debit Card",
                        "paymentMethodStatic": false,
                        "payPart": false,
                        "items": products,
                        "subtotal": total,
                        "percentDiscount": null,
                        "discounts": 0,
                        "total_amount": total,
                        "payment_details": paymentDetails,
                        "return_policy": 3,
                        "globalServiceIsWrench": false,
                        "order": false,
                        "shipping": deliveryOption === "home",
                        "errors": {},
                        "online_payment": true,
                        "onvo_pay_payment_intent_id": paymentIntent.paymentIntent.id,
                        "recaptcha_token": recaptchaToken
                    }
                )
                saleId.current = sale?.message;
                localStorage.clear();
                setShowPaymentModal(true);
            } catch (error) {
                console.error('Error processing payment:', error);
                setError('Ha ocurrido un error a la hora de procesar el pago')
                setShowErrorOnPay(true)
                setLoading(false)
            }
        } else {
            console.log('Form errors:', errors);
        }
    };

    return (
        <div className="flex flex-col items-center pt-16 bg-gray-50">
            <div className="m-auto flex-col text-center px-4 w-full flex items-center">
                <div onClick={() => { props.setShowPaymentFlow(false); }} className="cursor-pointer absolute top-14 right-0 p-4">
                    <img src="/svgs/x-icon.svg" alt="exit modal"/>
                </div>

                <img alt="ready-game-logo" className="w-[14rem] mb-8" src="/images/readyGamesLogo.png" />
                <div className="w-full md:w-[70%] flex flex-col">
                    <h1 className="self-center md:self-start text-3xl mb-8">Finalizar Compra</h1>
                    <div className="flex flex-col md:flex-row justify-center gap-x-[4rem] bg-white rounded-lg shadow-lg p-10 mb-20">
                        <form onSubmit={handleSubmit} className="w-full text-left" autoComplete={"on"}>

                            <h1 className="font-medium text-xl mb-4">Datos personales</h1>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-4">
                                <div className="mb-2">
                                    <label htmlFor="name" className="text-[#373f50] text-sm">Nombre completo </label>
                                    <input id="name" name="name" type="text" placeholder="Nombre completo" className="w-full p-2 border rounded"
                                        value={metadata.userName}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.userName && <p style={{ color: "red" }}>{errors.userName}</p>}
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="email" className="text-[#373f50] text-sm">Correo electrónico </label>
                                    <input id="email" name="email" type="email" placeholder="Correo electrónico" className="w-full p-2 border rounded" value={metadata.userEmail} onChange={handleInputChange} required />
                                    {errors.userEmail && <p style={{ color: 'red' }}>{errors.userEmail}</p>}
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="idType" className="text-[#373f50] text-sm">Tipo de identificación </label>
                                    <select id="idType" name="idType" className="w-full p-2 border rounded" value={shippingData.idType} onChange={handleInputChange} required>
                                        <option value="" className="">Seleccione tipo de identificación</option>
                                        <option value="fisica">Física</option>
                                        <option value="dimex">Dimex</option>
                                        <option value="pasaporte">Pasaporte</option>
                                        <option value="juridica">Jurídica</option>
                                        {/* Add options here */}
                                    </select>
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="id" className="text-[#373f50] text-sm">Número de identificación </label>
                                    <input id="id" name="id" type="number" placeholder="Número de identificación" className="w-full p-2 border rounded" value={metadata.userId}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.userId && <p style={{ color: "red" }}>{errors.userId}</p>}
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="phone" className="text-[#373f50] text-sm">Teléfono </label>
                                    <input id="phone" name="phone" type="tel" placeholder="Número de teléfono" className="w-full p-2 border rounded"
                                        value={metadata.phoneNumber}
                                        onChange={handleInputChange}
                                        required
                                    />
                                    {errors.phoneNumber && <p style={{ color: "red" }}>{errors.phoneNumber}</p>}
                                </div>
                            </div>

                            


                            <DeliveryDetails 
                            deliveryOption={deliveryOption}
                            setDeliveryOption={setDeliveryOption}
                            metadata={metadata}
                            errors={errors}
                            handleInputChange={handleInputChange}
                            ></DeliveryDetails>
                            
                            {/* Metadata */}

                            
                            <h1 className="font-medium text-xl mb-4">Metodo de pago</h1>

                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">


                                {/* Card data */}
                                <div className="mb-2">
                                    <label htmlFor="cardNumber" className="text-[#373f50] text-sm">Número de tarjeta</label>
                                    <input id="cardNumber" name="cardNumber" type="number" placeholder="Número de tarjeta" className="w-full p-2 border rounded" value={cardData.cardNumber} onChange={handleInputChange} required />
                                    {errors.cardNumber && <p style={{ color: 'red' }}>{errors.cardNumber}</p>}
                                </div>

                                <div className="mb-4">
                                    <label htmlFor="holderName" className="text-[#373f50] text-sm">Nombre del propietario de la tarjeta</label>
                                    <input id="holderName" name="holderName" type="text" placeholder="Nombre del propietario de la tarjeta" className="w-full p-2 border rounded" value={cardData.holderName} onChange={handleInputChange} required />
                                    {errors.holderName && <p style={{ color: 'red' }}>{errors.holderName}</p>}
                                </div>

                                <div className="flex flex-col md:flex-row mb-2">
                                    <div className="mr-0 mb-8 md:mb-0 md:mr-2 flex-grow">
                                        <label htmlFor="monthYear" className="text-[#373f50] text-sm">Fecha de expiración</label>
                                        <input id="monthYear" name="monthYear" type="month" min={minMonthYear} className="w-full p-2 border rounded" value={cardData.monthYear} onChange={handleInputChange} required />
                                    </div>
                                    <div>
                                        <label htmlFor="CVV" className="text-[#373f50] text-sm">CVV</label>
                                        <input
                                            id="CVV"
                                            name="CVV"
                                            type="number"
                                            placeholder="CVV"
                                            className="w-full p-2 border rounded"
                                            value={cardData.CVV}
                                            onChange={handleInputChange}
                                            maxLength="3"
                                            required
                                        />
                                        {errors.CVV && <p style={{ color: 'red' }}>{errors.CVV}</p>}
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="w-full my-8 md:my-0 md:w-[50%]">
                            <h2>Información de compra</h2>

                            {Array.isArray(products) && products.map((product, index) => (
                                <div key={index} className={`my-10 flex items-center ${product.state !== 'available' ? "opacity-50 grayscale" : ""}`}>
                                    <img
                                        className="max-w-[20%] mr-4 cursor-pointer"
                                        src={product?.image}
                                        alt={product?.name}
                                        onClick={() => document.location.href = "/product/"+product?.id}
                                    />
                                    <div className="flex flex-col items-start">
                                        <p className="cursor-pointer" onClick={() => document.location.href = "/product/"+product?.id}>{product?.name}</p>
                                        {product.state !== "available" ? 
                                            <span className="text-red-500 font-medium text-sm bg-red-100 px-2 py-1 rounded">Producto agotado</span>
                                        : 
                                            <>
                                                <p className="text-gray-600">Precio: ₡{product.payment?.sale_price?.toLocaleString()}</p>
                                            </>
                                        } 
                                    </div>
                                </div>
                            ))}
                            <h5> impuestos + servicio:<br /> ₡ {taxesPlusService.toLocaleString()}</h5><br />
                            {
                                shipping && deliveryOption === "home" ? <><h5> envío:<br /> ₡ {shipping.toLocaleString()}</h5><br /></>: <></>
                            }
                            <h3 className="font-bold text-2xl"> Total: ₡ {(paymentData.amount / 100).toLocaleString()} <span className='text-xs'>i.v.i.</span></h3>

                            <div className="flex gap-x-2 mt-10">
                                    <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                            onChange={handleRecaptcha}
                                    />
                            </div>
                            <div className="flex gap-x-2 mt-1">
                                    {errors.recaptchaToken && <p style={{ color: "red" }}>{errors.recaptchaToken}</p>}
                            </div>
                            <div className="flex gap-x-2 mt-10">
                                    <button onClick={e => { e.preventDefault(); props.setShowPaymentFlow(false); }} className="w-full p-2 bg-black text-white rounded hover:bg-gray-900">Cancelar</button>

                                    <button onClick={handleSubmit} disabled={loading} type="submit" className="w-full p-2 bg-blue-500 text-white rounded hover:bg-blue-600">{!loading ? 'Comprar' :
                                        <div role="status">
                                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gray-600 dark:fill-gray-300" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                            </svg>
                                            <span class="sr-only">Loading...</span>
                                        </div>}
                                    </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showSuccess && <SuccessfullPay setShowSuccess={setShowSuccess} />}
            {showErrorOnPay && <ErrorOnPay error={error} setShowErrorOnPay={setShowErrorOnPay} />}
            {showPaymentModal && <PaymentModal saleId={saleId.current}
             items={products}
             paymentSuccess={saleId.current !== undefined}></PaymentModal>}
        </div>
    );
};
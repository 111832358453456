import axios from "axios";

export class ProductsAPI {

    constructor() {
        this.base_url = "https://readygamescr.com";
    }

    getProducts({q="", tags="DESTACADO", consoles="", types="", page=1}) {
        return new Promise((resolve, reject) => {
            axios.get(`${this.base_url}/api/products/?page=${page}&q=${q}&consoles=${consoles}&types=${types}&tags=${tags}`)
                .then(res => {
                    resolve(res.data);
                })
                .catch(errors => {
                    reject(errors);
                });
            }
        );
    }

    getProduct(productId) {
        return new Promise((resolve, reject) => {
                axios.get(`${this.base_url}/api/product/${productId}/`)
                    .then(res => {
                        resolve(res.data);
                    })
                    .catch(errors => {
                        reject(errors);
                    });
            }
        );
    }

    createSale(sale) {
        return new Promise((resolve, reject) => {
            axios.post(`${this.base_url}/buy-online`, sale)
            .then(response => {
                resolve(response.data); // Resolves the promise with the response data.
            })
            .catch(error => {
                reject(error.response || error.message); // Rejects the promise with the error.
            });
        });
    }

}

export const PaymentModal = ({ saleId, items, paymentSuccess }) => {
    const stopPropagation = (e) => e.stopPropagation();

    return (
        <div onClick={() => {window.location.href = "/"}} className="fixed inset-0 z-50 overflow-auto bg-gray-900 bg-opacity-50 flex">
            <div onClick={stopPropagation} className="relative bg-white m-auto flex-col flex rounded-lg text-center px-4 py-8 overflow-hidden w-[450px]">
                
                <div onClick={() => {window.location.href = "/"}} className="cursor-pointer absolute top-1 right-0 p-4">
                    <img src="/svgs/x-icon.svg" alt="Cerrar" />
                </div>

                <h2 className="text-xl font-bold mb-4 cursor-default">Detalles de tu compra</h2>
                <ul className="text-left text-gray-500 font-[600]">
                    {items.map((item, index) => (
                        <li key={index} className="mb-2">{item.name} - ₡{item.payment?.card?.toLocaleString()}</li>
                    ))}
                </ul>

                {paymentSuccess !== null && (
                    <div className={`mt-4 p-3 rounded-lg text-white ${paymentSuccess ? 'bg-green-500' : 'bg-red-500'}`}>
                        {paymentSuccess ? 'Pago realizado con éxito. Recuerda revisar el correo.' : 'Hubo un problema con el pago'}
                        <br/>
                        {saleId && <>ID de venta: {saleId}</>}
                    </div>
                )}
            </div>
        </div>
    );
};

import React, { useEffect, useState } from 'react';
import { ProductsAPI } from '../../api/products';
import Navbar from '../Navbar/Navbar';
import { useContext } from 'react';
import { CartContext } from '../CartContext/CartContext';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { PaymentFlow } from './PaymentFlow';
import  CouponForm  from './CouponForm';

const CheckoutFlow = () => {
    const [cartProducts, setCartProducts] = useState([]);
    const { cart, removeFromCart } = useContext(CartContext);
    const [showPaymentFlow, setShowPaymentFlow] = useState(false);
    const [subtotal, setSubtotal] = useState(0);
    const api = new ProductsAPI();

    useEffect(() => {
        if (cart.length > 0) {
            Promise.all(cart.map(id => api.getProduct(id)))
                .then(products => {
                    setCartProducts(products);
                });
        } else {
            setCartProducts([]);
        }
    }, [cart]);

    useEffect(() => {
        let sum = 0;
        if (cartProducts.length > 0) {
            cartProducts.filter(
                product => product.state === "available"
            ).map((product) => {
                sum = sum + parseInt(product.payment.sale_price) || 0
            })
            
            setSubtotal(sum);
        }
    }, [cartProducts]);

    const handleRemove = (productId) => {
        removeFromCart(productId);
    };

    const backToStore = () => {
        document.location.href = "/";
    };

    const applyCouponCallback = () => {
    }

    if (cartProducts.length === 0) {
        return (
            <div className='h-[100vh] bg-gray-50'>
                <Navbar />
                <div className="flex flex-col items-center justify-center pt-16 bg-gray-50">
                    <h1 className="text-2xl font-bold text-gray-800 mb-4">Carrito vacío</h1>
                    <p className="text-gray-600">No hay productos en el carrito. ¡Agrega algunos!</p>
                    <button onClick={backToStore} className="mt-12 md:mt-12 bg-sky-700 animate__animated animate__pulse animate__repeat-3
                                 text-white rounded px-12 py-3 outline-blue-700">Volver a la tienda </button>
                </div>
            </div>
        );
    }

    return (
        <div className='h-[100vh] bg-gray-50'>
            <Navbar />
            {!showPaymentFlow ? 
                <div className="flex flex-col items-center pt-16 bg-gray-50">
                    <h1 className="text-3xl font-bold mb-6">Carrito de compras</h1>
                    <div className='flex flex-col md:flex-row justify-between md:w-[60%] md:mt-10'>
                        <div className='md:w-[70%] mx-8'>
                            <div className='flex align-center justify-between md:my-10 my-6'>
                                <h1 className="text-2xl font-bold">Productos</h1>
                                <button onClick={backToStore} className="hidden md:block border border-sky-700 animate__animated animate__pulse animate__repeat-3
                                        text-sky-700 rounded px-12 py-3 outline-blue-700">
                                                Continuar comprando
                                </button>
                            </div>
                            <div className="space-y-6 md:w-full mb-12">
                                {cartProducts.map((product) => (
                                    <div key={product.id} className={`flex items-center justify-between space-x-4 bg-white p-4 rounded-lg shadow`}>
                                        <div className={` flex items-center space-x-4 ${product.state !== 'available' ? "opacity-50 grayscale" : ""}`}>
                                            <img className="w-24 cursor-pointer" src={product.image} alt={product.name} onClick={() => document.location.href = "/product/"+product?.id}/>
                                            <div className="flex-1">
                                                <h2 className="text-lg font-semibold cursor-pointer" onClick={() => document.location.href = "/product/"+product?.id}>{product.name}</h2>
                                                {product.state !== "available" ? 
                                                    <span className="text-red-500 font-medium text-sm bg-red-100 px-2 py-1 rounded">Producto agotado</span>
                                                :
                                                    <p className="text-gray-600">Precio: ₡{product.payment?.sale_price?.toLocaleString()}</p>
                                                }
                                            </div>
                                        </div>
                                        <AiOutlineCloseCircle
                                            className="text-red-500 cursor-pointer"
                                            onClick={() => handleRemove(product.id)}
                                            size={24}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='bg-white md:w-[30%] mx-8 rounded-lg shadow-lg p-8 flex flex-col mb-12 h-[28rem]'>
                            <h1 className='self-center text-xl pb-4'>Carrito de compras</h1>
                            <p className='pb-3'>Subtotal:</p>
                            <p className='text-3xl self-center pb-3'>₡{subtotal ? subtotal?.toLocaleString(): 0}</p>
                            <hr className='my-2'></hr>
                            <CouponForm applyCouponCallback={applyCouponCallback}/>
                            <button className={`mt-6 px-6 py-3 ${subtotal > 0 ? 'bg-blue-500 hover:bg-blue-600': 'bg-gray-500'} text-white font-semibold rounded`}
                                onClick={() => setShowPaymentFlow(true)}
                                disabled={subtotal <= 0}
                            > 
                                Continuar al pago
                            </button>
                        </div>
                    </div>
                </div>
                
                :

                <PaymentFlow
                    products={cartProducts.filter(prod => prod.state === "available")}
                    setShowPaymentFlow={setShowPaymentFlow}
                />
            }
        </div>
    );
};

export default CheckoutFlow;
